import React, { useMemo, useCallback } from 'react';
import { Button, Stack } from '@mui/material';
import * as yup from 'yup';
import { useCustomForm, useToggle } from '../../utils/hooks';
import { useTranslation } from 'react-i18next';
import { alertUser } from '../../utils/common';
import { ClearableTextareaControlled, TextFieldControlled, AddUsersControlled } from '../inputs/form-wrappers';
import { SAVE_GROUP, UPDATE_GROUP } from '../../services/api-calls';
import { LoadingButton } from '@mui/lab';
import { useWatch } from 'react-hook-form';
import { AcceptPopup } from '../accept-popup';

const FORM_KEY = 'group';
const schema = yup
	.object({
		name: yup.string().max(100, 'forms.group.name.errors.max|${max}').required('forms.group.name.errors.required').default(''),
		description: yup
			.string()
			.max(3000, 'forms.group.description.errors.max|${max}')
			.required('forms.group.description.errors.required')
			.default(''),
		users: yup
			.array(yup.object({ id: yup.number().required() }))
			.min(1, 'forms.group.users.errors.not-empty')
			.required('forms.group.users.errors.required')
			.default([]),
	})
	.required();

export const GroupForm = ({ group = null }) => {
	const {
		register,
		handleSubmit,
		setFocus,
		setValue,
		formState: { isValid, isSubmitting, isDirty },
		formMode,
		setFormMode,
		control,
		cancel,
	} = useCustomForm(FORM_KEY, schema, group);
	const { t } = useTranslation();

	const onSuccess = useCallback(() => {
		alertUser(formMode === 'create' ? t('alerts.successfully-created-group') : t('alerts.successfully-updated-group'), 'success');
	}, [formMode, t]);

	const onSubmit = useMemo(
		() =>
			handleSubmit(async (data) => {
				const apiCall = formMode === 'create' ? SAVE_GROUP : UPDATE_GROUP;

				await apiCall(data, onSuccess);
			}),
		[handleSubmit, formMode, onSuccess],
	);

	return (
		<form onSubmit={onSubmit}>
			<Stack spacing={3}>
				<TextFieldControlled {...register('name', { controlled: true })} label={t('forms.group.name.label')} required />
				<ClearableTextareaControlled
					{...register('description', { controlled: true })}
					label={t('forms.group.description.label')}
					setFocus={setFocus}
					setValue={setValue}
					required
				/>
				<AddUsersControlled
					{...register('users', { controlled: true })}
					usersSearcherLabel={t('forms.group.users.label')}
					usersViewerLabel={t('broadcast.groups.users-viewer-label')}
					required
				/>
				<Buttons
					formKey={FORM_KEY}
					formMode={formMode}
					isValid={isValid}
					isSubmitting={isSubmitting}
					isDirty={isDirty}
					control={control}
					onSubmit={onSubmit}
					setFormMode={setFormMode}
					onCancel={cancel}
					t={t}
				/>
			</Stack>
		</form>
	);
};

const Buttons = ({ formMode, isValid, isSubmitting, isDirty, control, onSubmit, setFormMode, onCancel, t }) => {
	const getButton = useCallback(() => {
		switch (formMode) {
			case 'watch':
				return <Edit setFormMode={setFormMode} t={t} />;
			case 'create':
				return <Save isValid={isValid} isSubmitting={isSubmitting} t={t} />;
			case 'edit':
				return (
					<Update isValid={isValid} isDirty={isDirty} isSubmitting={isSubmitting} control={control} onSubmit={onSubmit} t={t} />
				);
			default:
				return null;
		}
	}, [control, formMode, isDirty, isSubmitting, isValid, onSubmit, setFormMode, t]);

	return (
		<Stack direction="row" spacing={2} width="100%">
			{getButton()}
			{formMode === 'edit' && (
				<Button variant="outlined" onClick={onCancel} sx={{ flex: 1, maxWidth: '250px' }}>
					{t('cancel')}
				</Button>
			)}
		</Stack>
	);
};

const Edit = ({ setFormMode, t }) => {
	return (
		<Button
			variant="contained"
			onClick={() => {
				setFormMode('edit');
			}}
			sx={{ flex: 1, maxWidth: '250px' }}
		>
			{t(`forms.group.buttons.edit`)}
		</Button>
	);
};

const Save = ({ isValid, isSubmitting, t }) => {
	return (
		<LoadingButton
			type="submit"
			variant={isValid ? 'contained' : 'outlined'}
			disabled={!isValid}
			loading={isSubmitting}
			sx={{ flex: 1, maxWidth: '250px' }}
		>
			{t(`forms.group.buttons.save`)}
		</LoadingButton>
	);
};

const Update = ({ isValid, isDirty, isSubmitting, control, onSubmit, t }) => {
	const groupName = useWatch({ control, name: 'name' });
	const [updatePopup, toggleUpdatePopup] = useToggle(false);

	const handlePopupClose = useCallback(() => {
		toggleUpdatePopup();
		onSubmit();
	}, [onSubmit, toggleUpdatePopup]);

	return (
		<>
			<Button
				variant={isValid ? 'contained' : 'outlined'}
				disabled={!isValid || !isDirty}
				loading={isSubmitting}
				onClick={toggleUpdatePopup}
				sx={{ flex: 1, maxWidth: '250px' }}
			>
				{t(`forms.group.buttons.update`)}
			</Button>
			<AcceptPopup
				id="update-group-popup"
				title={t('broadcast.groups.update-popup.title', { group: groupName })}
				text={t('broadcast.groups.update-popup.text')}
				isOpen={updatePopup}
				isDanger
				onClose={toggleUpdatePopup}
				action={handlePopupClose}
			/>
		</>
	);
};
