/**
 * Controlled wrappers around reusable components for integration with react-hook-form
 */

import React, { useCallback } from 'react';
import { useController } from 'react-hook-form';
import { ClearableTextarea } from './clearable-textarea';
import { UsersSearcher } from './users-searcher';
import { Box, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { UsersViewer } from '../users-viewer';
import { GroupsListSelector } from './groups-list-selector';

export const TextFieldControlled = ({ name, control, ...props }) => {
	const { field } = useController({ name, control });

	return (
		<TextField {...props} inputRef={field.ref} name={name} value={field.value ?? ''} onChange={field.onChange} onBlur={field.onBlur} />
	);
};

export const ClearableTextareaControlled = ({ name, control, setFocus, setValue, ...props }) => {
	const { field } = useController({ name, control });

	const handleClear = useCallback(() => {
		setValue(name, '', { shouldValidate: true });
		setFocus(name);
	}, [name, setFocus, setValue]);

	return (
		<ClearableTextarea
			{...props}
			inputRef={field.ref}
			name={name}
			value={field.value}
			onChange={field.onChange}
			onBlur={field.onBlur}
			onClear={handleClear}
		/>
	);
};

export const AddUsersControlled = ({ name, control, usersSearcherLabel, usersViewerLabel, disabled, error, helperText, required }) => {
	const { field } = useController({ name, control });

	return (
		<FormControl error={error}>
			{!disabled && (
				<>
					<FormLabel sx={{ mb: 2 }} required={required}>
						{usersSearcherLabel}
					</FormLabel>
					<Box mb={2}>
						<UsersSearcher selectedUsers={field.value} onChange={field.onChange} />
					</Box>
				</>
			)}
			{field.value?.length > 0 && (
				<>
					<FormLabel sx={{ mb: 2 }} required={required}>
						{usersViewerLabel}
					</FormLabel>
					<UsersViewer users={field.value} enableSelectingUsers={!disabled} onChange={field.onChange} />
				</>
			)}

			{error && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};

export const GroupsListSelectorControlled = ({ name, control, disabled }) => {
	const { field } = useController({ name, control });
	const ids = field.value?.map((group) => group.id);

	const handleChange = useCallback(
		(changedIds) => {
			field.onChange(changedIds ? changedIds.map((id) => ({ id })) : []);
		},
		[field],
	);

	return <GroupsListSelector checkedIds={ids} onChange={handleChange} disabled={disabled} />;
};
