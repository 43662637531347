import React, { useMemo, useCallback } from 'react';
import { Button, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { useCustomForm, useToggle } from '../../utils/hooks';
import { useTranslation } from 'react-i18next';
import { SEND_MESSAGE } from '../../services/api-calls';
import { alertUser } from '../../utils/common';
import { ClearableTextareaControlled, GroupsListSelectorControlled } from '../inputs/form-wrappers';
import { AcceptPopup } from '../accept-popup';
import { useWatch } from 'react-hook-form';

const FORM_KEY = 'send-message';
const schema = yup
	.object({
		subject: yup
			.string()
			.max(100, 'forms.send-message.subject.errors.max|${max}')
			.required('forms.send-message.subject.errors.required')
			.default(''),
		bodyText: yup
			.string()
			.max(3000, 'forms.send-message.bodyText.errors.max|${max}')
			.required('forms.send-message.bodyText.errors.required')
			.default(''),
		groups: yup
			.array(yup.object({ id: yup.number().required() }))
			.required()
			.default([]),
	})
	.required();

export const SendMessageForm = ({ message, enableGroupsSelection = false }) => {
	const {
		register,
		handleSubmit,
		setFocus,
		reset,
		setValue,
		control,
		formMode,
		setFormMode,
		cancel,
		formState: { isValid, isSubmitting, isDirty },
	} = useCustomForm(FORM_KEY, schema, message);
	const { t } = useTranslation();

	const onSuccess = useCallback(() => {
		alertUser(t('alerts.send-messages-started'), 'info');
		reset(schema.getDefault());
	}, [reset, t]);

	const onSubmit = useMemo(
		() =>
			handleSubmit(async (data) => {
				await SEND_MESSAGE({ ...data }, onSuccess);
			}),
		[handleSubmit, onSuccess],
	);

	return (
		<form onSubmit={onSubmit}>
			<Stack spacing={3}>
				<TextField {...register('subject')} label={t('forms.send-message.subject.label')} required />
				<ClearableTextareaControlled
					{...register('bodyText', { controlled: true })}
					label={t('forms.send-message.bodyText.label')}
					control={control}
					setFocus={setFocus}
					setValue={setValue}
					required
				/>
				{enableGroupsSelection && <GroupsListSelectorControlled {...register('groups', { controlled: true })} />}
				<Buttons
					formMode={formMode}
					isValid={isValid}
					isSubmitting={isSubmitting}
					isDirty={isDirty}
					onSubmit={onSubmit}
					setFormMode={setFormMode}
					onCancel={cancel}
					control={control}
					t={t}
				/>
			</Stack>
		</form>
	);
};

const Buttons = ({ formMode, isValid, isSubmitting, isDirty, onSubmit, setFormMode, control, onCancel, t }) => {
	const getButton = useCallback(() => {
		switch (formMode) {
			case 'watch':
				return (
					<Edit
						isValid={isValid}
						isDirty={isDirty}
						isSubmitting={isSubmitting}
						setFormMode={setFormMode}
						onSubmit={onSubmit}
						t={t}
					/>
				);
			case 'create':
				return <Save isValid={isValid} isSubmitting={isSubmitting} control={control} onSubmit={onSubmit} t={t} />;
			case 'edit':
				return <Update isValid={isValid} isDirty={isDirty} isSubmitting={isSubmitting} onSubmit={onSubmit} t={t} />;
			default:
				return null;
		}
	}, [control, formMode, isDirty, isSubmitting, isValid, onSubmit, setFormMode, t]);

	return (
		<Stack direction="row" spacing={2} width="100%">
			{getButton()}
			{formMode === 'edit' && (
				<Button variant="outlined" onClick={onCancel} sx={{ flex: 1, maxWidth: '250px' }}>
					{t('cancel')}
				</Button>
			)}
		</Stack>
	);
};

const Edit = ({ isValid, isDirty, isSubmitting, setFormMode, onSubmit, t }) => {
	return (
		<>
			<Update isValid={isValid} isDirty={isDirty} isSubmitting={isSubmitting} onSubmit={onSubmit} forcedEnable t={t} />
			<Button
				variant="contained"
				onClick={() => {
					setFormMode('edit');
				}}
				sx={{ flex: 1, maxWidth: '250px' }}
			>
				{t(`forms.send-message.buttons.edit`)}
			</Button>
		</>
	);
};

const Save = ({ isValid, isSubmitting, control, onSubmit, t }) => {
	const groups = useWatch({ control, name: 'groups' });
	const enablePopup = !groups || groups.length === 0;
	const [sendPopup, toggleSendPopup] = useToggle(false);

	const handlePopupClose = useCallback(() => {
		toggleSendPopup();
		onSubmit();
	}, [onSubmit, toggleSendPopup]);

	return (
		<>
			<LoadingButton
				variant={isValid ? 'contained' : 'outlined'}
				disabled={!isValid}
				loading={!enablePopup && isSubmitting}
				sx={{ flex: 1, maxWidth: '250px' }}
				onClick={enablePopup ? toggleSendPopup : onSubmit}
			>
				{t(`forms.send-message.buttons.save`)}
			</LoadingButton>
			{enablePopup && (
				<AcceptPopup
					id="send-globally-popup"
					title={t('broadcast.messages.send-globally-popup.title')}
					text={t('broadcast.messages.send-globally-popup.text')}
					isOpen={sendPopup}
					isDanger
					onClose={toggleSendPopup}
					action={handlePopupClose}
				/>
			)}
		</>
	);
};

const Update = ({ isValid, isDirty, isSubmitting, onSubmit, forcedEnable, t }) => {
	const [resendPopup, toggleResendPopup] = useToggle(false);

	const handlePopupClose = useCallback(() => {
		toggleResendPopup();
		onSubmit();
	}, [onSubmit, toggleResendPopup]);

	return (
		<>
			<Button
				variant={isValid ? 'contained' : 'outlined'}
				disabled={(!isValid || !isDirty) && !forcedEnable}
				loading={isSubmitting}
				onClick={toggleResendPopup}
				sx={{ flex: 1, maxWidth: '250px' }}
			>
				{t(`forms.send-message.buttons.update`)}
			</Button>
			<AcceptPopup
				id="resend-popup"
				title={t('broadcast.messages.resend-popup.title')}
				text={t('broadcast.messages.resend-popup.text')}
				isOpen={resendPopup}
				isDanger
				onClose={toggleResendPopup}
				action={handlePopupClose}
			/>
		</>
	);
};
